.AppHead {
    width: 100%;
    display: flex;
    height:var(--appHeader-height);
    background: rgb(67,89,91);
    background: linear-gradient(148deg, rgb(28, 40, 41) 0%, rgba(0,0,0,1) 78%);
    border-bottom: #555 2px solid;
    border-image: linear-gradient(to right, transparent, rgb(119, 119, 88), transparent) 1;
    position: fixed;
    z-index: 5;
    top:0;
    left:0;
    padding: 3vh 1vw;
}
.contentNav a {
    position: relative;
}
.newUsersAlert {
    border-radius: 50%;
    line-height: 120%;
    color:#fff;
    display:block;
    position:absolute;
    border: 1px solid #ac0202;
    top:-1.2em;
    right:-0.7em;
    font-weight: 900;
    font-size: 80%;
    z-index: 6;
    width:1.3em;
    height:1.4em;
    text-align: center;
    background-color: #860000;
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {opacity: 0.5;}
    50% {opacity: 1;}
    100% {opacity: 0.5;}
}

.main {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
}
.mainMenu, .contentNav {
    display: flex;
    align-items:center;
    gap:1vw;
    justify-content: right;
    padding-right: 1vw;
}
.logo {
    height:var(--normalLogo-height);
}
.diceButton > .diceIcon {
    width: 2vw;
    margin-bottom: -1vh;
}
.diceButton {
    padding: 1vh;
}

.diceButton:hover > .diceIcon,
.diceButton:active > .diceIcon {
    filter: brightness(200%);
    transition: all 0.8s;
}
.hamburger {
    display: none;
}

@media (max-width: 800px) {
    .logo {
        height:var(--mobileLogo-height);
        margin:1vw;
    }
    .hamburger {
        display: flex;
    }
    .contentNav {
        position:fixed;
        display: flex;
        align-items: center;
        gap:3vh;
        padding: 2vh 0;
        left:0;
        top:15vh;
        flex-direction: column;
        width:100vw;
        padding-right: 1vw;
        background: var(--decorative-bg1);
        border-bottom: var(--decorative-border2-base);
        border-image: var(--decorative-border2);
        /* animation: slideDown 0.3s; */
        transition: transform 0.2s;
        transform-origin: top;
        transform: scaleY(0%);
    }
    .hamburger > input[type='checkbox'] {
        position:relative;
        display: flex;
        color:var(--button-text-color);  
        border: none;
        text-transform: uppercase;
        font-size: 20vw;
        height:8vh;
    }
    .hamburger > input[type='checkbox']:after {
        position:absolute;
        content: '≡';
        display: block;
        bottom: 2vh;
        right: 3vw;
        line-height: 0;
        transform: rotate(0deg);
        transition: all 0.2s;
    }
    .hamburger > input[type='checkbox']:checked::after {
        transform: rotate(-90deg);
        color: #fff;
    }
    .hamburger > input[type='checkbox']::before, 
    .hamburger > input[type='checkbox']:checked::before
    {
        display:none;
    }
    .contentNav > * {
        width:60%;
        text-align: center;
    }
    .diceButton > .diceIcon {
        width: 8vw;
    }
    .hamburgerOpened { 
        transform: scaleY(100%);
    }


}

