.item {
    display: grid;   
    gap:1vh;
    text-align: left;
}
.item > div > span {
    display: block;
}
.item > div:empty {
    display: none;
}
.item > div:not(:empty):not(:last-child) {
    border-bottom: 1px solid #2a2a2a;
    /* border-image: var(--border-img-h2); */
    padding: 0.5vh;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: center;
    /* text-align: center ; */
    align-items: center;
    gap:1vw;
}
/* .item > .universesWrapper {
    grid-template-columns: repeat(3,1fr) !important;
} */
.item .range {
    justify-content: center;
    background-image: url('../assets/range_bck.png');
    background-repeat: no-repeat;
    background-size: 25vh;
    background-position:29% 0;
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: color-dodge;

}
.item .range > span {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:0.2vw;
}
.item .range span {
    display: grid;
    grid-template-rows: 1fr;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 0;
    gap:0.3vh;
    min-height: 2.5vh;
    position: relative;
}

.universes {
    display: grid;
    grid-template-rows: 1fr 1fr;
    /* justify-content: center; */
    /* align-items: center; */
    text-align: left;
}
.item button {
    max-width: 6vw;
    text-align: center;
    display: grid;
    justify-content: center;
}
@media (max-width:800px) {
    .item button {
        max-width: 100%;
    }
}
.item .amnt {
    font-size: 150%;
    color: #fff;
}



.rangeSlot {
    border: 3px solid #999;
    background-color: #333;
    border-radius: 50%;
    width:3vh;
    height: 3vh;
    position: absolute;
    top:-1vh;
    left:0;
}
.rangeFill {
    background-color: #999;
    border-radius: 50%;
    width:1.5vh;
    height: 1.5vh;
    position: absolute;
    top:0.4vh;
    left:0.4vh;
}

   
button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}