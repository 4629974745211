.formError {
    color: rgb(203, 0, 0);
}
.editMod input::-webkit-outer-spin-button,
.editMod input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.editMod input[type=number] {
  appearance: textfield;
}
.portrait {
    width: 50vh;
    height: 50vh;
    max-width: 100%;
    object-fit: cover;
    border:var(--decorative-border1-base);
    border-image:var(--decorative-border1);
}
/* .portraitWrapper {
  width:100%;
} */
.controls {
  display:flex;
  gap:2vw;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.controls div:empty {
  display: none;
}

.newChar {
  display: grid;
  /* flex-direction: column; */
  grid-template-rows: auto;
  gap:3vh;
  padding:3vh;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.newChar > * {
  grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
  display: grid;
}
.charEditForm > nav {
  display: grid;
  grid-template-rows: auto;
  gap:1vh;
  justify-content: stretch;
}
.charEditForm h3 {
  border-bottom: #555 1px solid;
  border-image: var(--border-img-h2);
}

.charSheet,
.charSheet > * {
  display: grid;
  grid-template-rows: auto;
  gap:3vh;
}

.progress {
  margin-bottom:150px;
}
.AddItemBtn {
  /* max-width: 50vw; */
  box-sizing: content-box;
  padding: 2vh 3vh;
  margin-top:3vh;
  margin-bottom: 3vh;
  width:100%;
}

.inputName {
  display:grid;
  /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  padding:1vh 2vw;
  gap:2vw;
  border-bottom: #555 1px solid;
  border-image: var(--border-img-li);
}
.inputName label span {
  margin-left:0.5vw;
  opacity:0.5;
}
.miscItemsHeaders {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}