.textContainer {
    animation: fadeIn 2s;
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height:85vh;
}
.buttonWrap{
    animation: fadeIn2 3s;
}
@keyframes fadeIn {
    0% { opacity:0; }
    25% { opacity:0; }
    100% { opacity:1; }
}
@keyframes fadeIn2 {
    0% { opacity:0; }
    60% { opacity:0; }
    100% { opacity:1; }
}