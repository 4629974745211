.Resource,
.ResourceEdit {
    display:grid;
    /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    padding:1vh 2vw;
    gap:2vw;
    border-bottom: #555 1px solid;
    border-image: var(--border-img-li);
}
.Resource {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.ResourceEdit {
    /* grid-template-columns: repeat(auto-fit,auto); */
    grid-template-rows: auto;
}
@media (max-width:800px) {
    .ResourceEdit {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .Resource {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
}


.Resource > *,
.ResourceEdit > *{
    display: flex;
    gap:2vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
    max-width: 100%;;
}
.Resource > *:empty,
.ResourceEdit > *:empty {
    display: none;
}
.ResourceEdit input {
    max-width:100%;
}
.ResourceEdit label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.ResourceEdit .priceInput {
    border:none;
    max-width: 4vw;
}
.Resource .price,
.ResourceEdit .priceInput {
    font-size: 120%;
    color: #fff;
}
.ResourceEdit textarea {
    /* width:100%; */
    height:20vh;
    width:50vw;
    display:flex;
    resize:none;
}
button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ResourceEdit input[type='number'] {
    width: 30px;
    background: none;
    border: none;
    color: #fff;
    font-size: 130%;
    font-weight: 900;
    /* overflow: visible; */
}

.ResourceEdit input::-webkit-outer-spin-button,
.ResourceEdit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ResourceEdit input[type=number] {
  appearance: textfield;
}

.Resource h4 {
    margin-top:0;
    margin-bottom:0;
}
.Resource:hover {
    cursor: pointer;
    background-color: #161616;
}