.li > div + div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.li img {
    max-height: 15vh;
    max-width: 15vw;
    /* mix-blend-mode: multiply; */
}
