.Character {
    /* margin-top: calc(var(--appHeader-height) + var(--tools-height)); */
    margin-top: var(--appMarginTop);
    padding: var(--text-padding);
    text-align: justify;
}

.portrait {
    max-width: 200px;
}
.deletedInfo {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height:60vh;
    animation: infoFadeIn 1.3s;
}

@keyframes infoFadeIn {
    0%{ opacity: 0 }
    100%{ opacity: 1 }
}

@media (max-width:800px) {
    .Character {
        /* margin-top: calc(var(--appHeader-height) + var(--tools-height) + 2vh); */
        margin-top: var(--appMarginTop);
    }
}