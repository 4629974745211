
@media (min-width:801px) {
    .InputText.hasSection,
    .InputTextEdit.hasSection {
        display:grid;
        padding:1vh 2vw;
        gap:2vw;
        grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
        border-bottom: #555 1px solid;
        border-image: var(--border-img-li);
    }
    .InputText:not(.hasSection),
    .InputTextEdit:not(.hasSection) {
        display:grid;
        /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
        grid-template-columns: repeat(6,1fr);
        padding:1vh 2vw;
        gap:2vw;
        border-bottom: #555 1px solid;
        border-image: var(--border-img-li);
    }
    .InputText:not(.hasSection) {
        gap:0;
    }
}
@media (max-width:800px) {

    .InputText:not(.hasSection),
    .InputTextEdit:not(.hasSection) {
        display:grid;
        /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
        grid-template-rows: auto;
        padding:1vh 2vw;
        gap:2vw;
        border-bottom: #555 1px solid;
        border-image: var(--border-img-li);
    }

    .InputText.hasSection,
    .InputTextEdit.hasSection {
        display:grid;
        padding:1vh 2vw;
        gap:2vw;
        grid-template-rows: auto;
        border-bottom: #555 1px solid;
        border-image: var(--border-img-li);
    }
}

.InputText > *,
.InputTextEdit > *{
    display: flex;
    gap:1vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
}
.InputTextEdit input {
    max-width:100%;
}
.InputTextEdit label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.InputTextEdit .priceInput {
    border:none;
    max-width: 4vw;
}
.InputText .price,
.InputTextEdit .priceInput {
    font-size: 120%;
    color: #fff;
}
.InputTextEdit textarea {
    /* width:100%; */
    height:20vh;
    width:50vw;
    display:flex;
    resize:none;
}

.AddItemBtn {
    /* max-width: 50vw; */
    box-sizing: content-box;
    padding: 2vh 3vh;
    margin-top:3vh;
    margin-bottom: 3vh;
    width:100%;
  }

.InputTextEdit input::-webkit-outer-spin-button,
.InputTextEdit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.InputTextEdit input[type=number] {
  appearance: textfield;
}
button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InputText:hover {
    cursor: pointer;
    background-color: #161616;
}