.loginBox {
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
}
.loginForm {
    grid-row: 1;
    display: grid;
    grid-template-rows: repeat(3,1fr);
    gap:1vh;
    padding:1vh;
}
.label{
    display: block;
    margin:0 auto;
}

.formInput, .label button {
    min-width: 30vmin;
    width:100%;
}
/* .formSpan {
    text-align: right;
    min-width: 20vmin;
} */
