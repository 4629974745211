.Article {
    /* margin-top: var(--appHeader-height); */
    margin-top: var(--appMarginTop);
    padding: var(--text-padding);
    text-align: justify;
}
.pseudoLink {
    text-decoration: underline;
    color:blue;
}
.NewArticle {
    display: grid;
    grid-template-rows: auto;
    gap:2vh;
    justify-content: center;
    align-items: center;
    padding:var(--text-padding);
    max-width: 100%;
    min-width: 70%;
}
.NewArticle > * {
    width:100%;
}
.NewArticle > .hide {
    display: none;
}