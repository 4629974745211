.statCounter label {
    display: flex;
}
.statCounter label > div {
    text-align: center;
}

.statCounter input {
    width: 30px;
    background: none;
    border: none;
    color: #fff;
    font-size: 130%;
    font-weight: 900;
}

.statCounter input[type='number'] {
    width: 30px;
    background: none;
    border: none;
    color: #fff;
    font-size: 130%;
    font-weight: 900;
    /* overflow: visible; */
}
.statCounter input::-webkit-outer-spin-button,
.statCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.statCounter input[type=number] {
  appearance: textfield;
}

.stat,
.statCounter {
    display:grid;
    /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    grid-template-columns: repeat(6,1fr);
    padding:1vh 2vw;
    gap:2vw;
}

.stat > *,
.statCounter > *{
    display: flex;
    gap:1vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
}

.stat .price,
.statCounter .priceInput {
    font-size: 120%;
    color: #fff;
}
/* .statCounter label > div {
    overflow: hidden;
} */
.statCounter label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.pointsButtonsBox {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: baseline;
}
.pointsButtonsBox > * {
    text-align: center;
    display: grid;
    gap:1vh;
    justify-content: center;
    justify-items: center;
}
button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stat:hover {
    cursor: pointer;
    background-color: #161616;
}