.characterSheet {
    width:297mm;
    height:210mm;
    display: grid;
    grid-template-columns: 22fr 1fr;
    font-size: 2.8mm;
    line-height:0.8;
    color: rgb(187, 187, 187);
    text-overflow: ellipsis;
    border: 50px solid transparent;
    box-sizing: content-box;
}
.characterSheet div {
    outline: 1px double #333;
    overflow: visible;
    text-overflow: ellipsis;
    min-width: 0px;
    min-height: 0px;
    display: grid;
    position: relative;
}
.characterSheet p {
    display: inline-block;
    margin:0.7mm 0;
    padding:1mm;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}
.characterSheet h5 {
    font-size: 2mm;
    /* opacity:0.7; */
    display: block;
    padding:0.4mm;
    text-transform: uppercase;
    font-weight: 100;
    position:absolute;
    /* background-color: #000000; */
    top:-4.5mm;
    z-index: 3;
    left:0;
    right:0;
    text-align: center;
}
.page {
    margin-top:var(--appMarginTop);
}


/* LEVEL 1 */
.main {
    grid-column: 1;
    grid-template-rows: 5fr 12fr 5fr 1.5fr;
    outline: none !important;
}
.rightSide {
    grid-column:2;
    padding:3.5mm;
    gap:2.5mm;
    outline: none !important;
}


/* LEVEL 2 */
.up {
    grid-row: 1;
    grid-template-columns: 2fr 3fr 4fr 9fr;
}
    /* APPEARANCE */
    .appearance {
        grid-column: 1;
        grid-template-rows: 5fr 1fr 1fr 2fr;
    }
        .portrait {
            grid-row:1;
            background-repeat: no-repeat;
        }
        .ageHei {
            grid-row:2;
            grid-template-columns: 1fr 1fr;
        }
            .age, .height {
                text-align: center;
                align-items: center;
            }
        .posture {
            grid-row:3;
        }
        .appearDesc {
            grid-row:4;
        }

    /* METRICS */
    .metrics {
        grid-column: 2;
        grid-template-rows: 2fr 2fr 1fr 1fr 1fr 2fr;
    }
        .name {
            grid-row:1;
            text-align: center;
        }
        .name h2 { 
            text-align: center;
        }
        .profDesc {
            grid-row:2;
        }
        .socialState {
            grid-row:3;
        }
        .origin {
            grid-row:4;
        }
        .folk {
            grid-row:5;
        }

    /* ROLEPLAY */
    .roleplay {
        grid-column: 3;
        grid-template-rows: repeat(6,1fr);
    }
        .characterType {
            grid-row:1;
        }
        .goals {
            grid-row:2;
        }
        .roles {
            grid-row:3;
        }
        .disorders {
            grid-row:4;
        }
        .otherPersonality {
            grid-row:5;
        }

    /* PROGRESSES */
    .progresses {
        grid-column: 4;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
        .progress {
            padding:4mm;
        }
            .progressTable {
                grid-template-columns: 3fr 5fr;
            }
                .points {
                    grid-template-columns: repeat(10,1fr);
                }
                    .filledPoint {
                        background-color: rgba(200, 200, 200, 0.2);
                    }

.middle {
    grid-row: 2;
    grid-template-columns: 3fr 9fr 4fr 5fr;
    outline: none !important;
    padding-bottom:2mm;
}
    /* COMPETENCES */
    .competences {
        grid-column: 1;
        border: 1.5mm solid #333;
    }
        .bonusHead p {
            letter-spacing: -0.4px;
            padding:0.5mm;
        }
        .compHead, .comp {
            grid-template-columns: 3fr 1fr;
        }
        .compCatHead {
            text-align: right;
        }
        .compHead div {
            align-items: center;
            text-align: center;
        }
        .compName {
            padding-top: 1mm;
            font-weight: 600;
        }
        .compValue {
            font-size: 120%;
            overflow: visible;
        }
        .compValue p {
            padding-left: 2.5mm;
            overflow: visible;
            z-index: 3;
            align-items: center;
        }
        .fightingIcon, .marksmanshipIcon, .stealthIcon, .armorLevelIcon {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            position:absolute;
            filter: invert(0.8);
            z-index: 2;
        }
        .fightingIcon {
            background-image: url('../assets/icon-fighting.png');
            width: 190%;
            height: 190%;
            top:-45%;
            left:-50%;
        }
        .marksmanshipIcon {
            background-image: url('../assets/icon-marksmanship.png');
            width: 150%;
            height: 150%;
            top:-20%;
            left:-30%;
        }
        .stealthIcon {
            background-image: url('../assets/icon-stealth.png');
            width: 170%;
            height: 170%;
            top:-35%;
            left:-40%;
        }
        .armorLevelIcon {
            background-image: url('../assets/icon-armorLevel.png');
            width: 170%;
            height: 170%;
            top:-35%;
            left:-40%;
        }

    /* UTENSILIA MID */
    .utensiliaMid {
        grid-column: 2;
        grid-template-rows: 1fr 1fr;
        gap:2mm;
        padding:2mm 2mm 0 2mm;
        outline: none !important;
    }
        .utensiliaMid > div {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2mm;
        }
            .weapon {
                grid-template-rows: 3fr 5fr 2fr 12fr;
                border: 0.5mm solid #333;
            }
            .camouflage, .armor {
                grid-template-rows: 2fr 2fr 12fr;
                border: 0.5mm solid #333;
            }
            .shield {
                grid-template-rows: 3fr 5fr 2fr 12fr;
                border: 0.5mm solid #333;
            }
            .rangedWeapon {
                grid-template-rows: 3fr 3fr 3fr 13fr;
                border: 0.5mm solid #333;
            }
            .utensName {
                align-items: center;
                text-align: center;
            }
            .utensValue, .utensValueReload {
                font-size: 130%;
                display: block;
                position: absolute;
                right:3mm;
                top:0.5mm;
                z-index: 3;
            }
            .utensValueReload {
                right:1mm;
                top:1mm;
            }
            .utensRange  {
                grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
                background-image: url('../assets/range_bck.png');
                background-repeat: no-repeat;
                background-size: 60%;
                background-position:8mm 0mm;
                background-color: rgba(0, 0, 0, 0.5);
                background-blend-mode: color-dodge;
            }
            .utensRange div {
                outline: none;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            /* .utensRange .range1 {
                background-image: url('../assets/range1.png');
            }
            .utensRange .range2 {
                background-image: url('../assets/range2.png');
            }
            .utensRange .range3 {
                background-image: url('../assets/range3.png');
            } */
            .rangeSlot {
                border: 0.7mm solid #999;
                background-color: #333;
                border-radius: 50%;
                width:4.5mm;
                height: 4.5mm;
                position: absolute;
                top:4.3mm;
                left:2.1mm;
            }
            .rangeFill {
                background-color: #999;
                border-radius: 50%;
                width:2mm;
                height: 2mm;
                position: absolute;
                top:-1.5mm;
                left:3.3mm;
            }
            .weapon .fightingIcon {
                right:-2mm;
                top:-3mm;
                width: 10mm;
                height: 10mm;
                left: auto;
            }
            .camouflage .stealthIcon {
                right:-2mm;
                top:-2.5mm;
                width: 10mm;
                height: 10mm;
                left: auto;
            }
            .rangedWeapon .marksmanshipIcon {
                right:-2mm;
                top:-2.5mm;
                width: 10mm;
                height: 10mm;
                left: auto;
            }
            .rangedWeapon .reloadIcon {
                border: 0.5mm solid #999;
                display: block;
                position:absolute;
                top:0;
                right: -1mm;
                height:6mm;
                width:6mm;
                z-index: 3;
                background-color: #333;
            }
            .utensiliaMid .armorLevelIcon {
                right:-2mm;
                top:-2mm;
                width: 10mm;
                height: 10mm;
                left: auto;
            }

    /* ABILITIES */
    .abilities {
        grid-column: 3;
        grid-template-rows: repeat(19,1fr);
        padding-right:2mm;
    }
        .abilities div {
            grid-template-columns: 6fr 1fr;
        }
            .abiHead {
                align-items: center;
                text-align: center;
                text-transform: uppercase;
            }

    /* INVENTORY */
    .inventory {
        grid-column: 4;
        grid-template-rows: 1fr 1fr 5fr 1fr 5fr 1fr 5fr;
    }
    .invSection, .invTwinHead  {
        grid-template-columns: 1fr 1fr;
    }
    .invHead, .invTwinHead {
        text-align: right;
        text-transform: uppercase;
        letter-spacing: -0.1mm;
        font-size: 80%;
    }
    .invMainHead {
        align-items: center;
        text-align: center;
    }
    .invSingleSection p {
        column-count: 2;
        column-gap: 2mm;
        columns:2;
        column-fill: auto;
        -moz-column-count: 2;
        -moz-column-gap: 2mm;
        -moz-columns:2;
        -moz-column-fill: auto;
    }
        

.bottom {
    grid-row: 3;
    grid-template-columns: 3fr 9fr 5fr 2fr 2fr;
    outline: none !important;
}
    /* STATE */
    .state {
        grid-column: 1;
        grid-template-rows: 1fr 9fr;
        border: 1.5mm solid #333;
    }
        .stateHead {
            align-items: center;
            text-align: center;
        }
    /* UTENSILIA BOT */
    .utensiliaBot {
        grid-column: 2;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 2mm 2mm 2mm;
        gap:2mm;
        outline: none !important;
    }
    /* MISC */
    .misc {
        grid-column: 3;
        grid-template-rows: repeat(10,1fr);
        border: 0.5mm solid #333;
    }
        .miscHead, .miscItem {
            grid-template-columns: 10fr 9fr 2fr;
        }
        .miscHead > div {
            align-items: center;
            text-align: center;
        }
        .miscBonusHead {
            font-size: 65%;
            display: block;
            position: absolute;
        }

    /* MOUNT */
    .mount {
        grid-column: 4;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 3fr;
        border: 0.5mm solid #333;
    }
        .mountStat {
            grid-template-columns: 4fr 1fr;
        }
        .mountName {
            align-items: center;
            text-align: center;
        }
        .mount .longStatName {
            font-size: 70%;
        }

    /* WEALTH */
    .wealth {
        grid-column: 5;
        grid-template-rows: 1fr 3fr 3fr;
        border: 0.5mm solid #333;
    }
        .wealthLevel {
            grid-template-columns: 4fr 1fr;
        }

.downSide {
    grid-row: 4;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 4mm;
    gap:3mm;
    outline: none !important;
}

/* AMMO */
.ammo {
    grid-template-columns: 3fr 3fr;
}
    .ammo .resInfo {
        grid-template-columns: 3fr 1fr;
        border-right:1.5mm solid #999;
    }
    .ammo .resAmount {
        grid-template-columns: repeat(6,1fr);
    }
    .ammo .resVal, 
    .ammo .resAmount {
        font-size: 120%;
        text-align: center;
    }
    .ammo .resAmount .fill {
        background: #999;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }
    .ammo .resAmount .few .fill {
        height:20%; 
    }
    .ammo .resAmount .quite .fill {
        height:55%; 
    }
    .ammo .resAmount .much .fill {
        height:100%; 
    }

/* RESOURCES */
.resource {
    grid-template-rows: 6fr 3fr;
}
    .resource .resInfo h5 {
        transform: rotate(-90deg) translateY(-3mm) translateX(-6mm);
    }
    .resource .resAmount h5 {
        transform: rotate(-90deg) translateY(-3mm) translateX(-13mm);
    }
    .resource .resAmount p {
        transform: rotate(-90deg);
        line-height: 0.2;
    }
    .resource .resInfo p {
        transform: rotate(-90deg) translateY(-1mm);
        font-size: 80%;
        line-height:1;
        width:100%;
        overflow: visible;
        display:block;
    }
    .resource .resInfo {
        border-top:1.5mm solid #999;
    }
    .resource .resAmount {
        grid-template-rows: repeat(6,1fr);
    }
    .resource .resVal, 
    .resource .resAmount {
        font-size: 120%;
        text-align: center;
    }
    .resource .resAmount .fill {
        background: #999;
        position: absolute;
        height: 100%;
        bottom: 0;
        right: 0;
    }
    .resource .resAmount .few .fill {
        width:20%; 
    }
    .resource .resAmount .quite .fill {
        width:55%; 
    }
    .resource .resAmount .much .fill {
        width:100%; 
    }

.resChecked {
    border: 1.0mm solid #999;
    outline: none !important;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
}




@media only screen {
     h5 {
        color:#999;
        text-shadow: rgb(0, 0, 0) 4px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
     } 
     .characterSheet {
        border-image: url('../assets/border.png') 140 stretch;
        background: radial-gradient(#0c1311, transparent) no-repeat;
    }   
}

@media only print {    
    @page { 
        size: A4 landscape;
        margin: 0;
        scale: 100;
    }
    body {
        /* transform: rotate(-90deg) scale(0.68,0.68); */
        background-color: white;
    }
    .characterSheet {
        background-color: rgb(255, 255, 255);
        color: #333;
        margin: 5mm auto;
        border: 0;
    }
    h5 {
        color:#fff;
        /* text-shadow: rgb(255, 255, 255) 4px 0px 0px, rgb(255, 255, 255) 2.83487px 0.981584px 0px, rgb(255, 255, 255) 2.35766px 1.85511px 0px, rgb(255, 255, 255) 1.62091px 2.52441px 0px, rgb(255, 255, 255) 0.705713px 2.91581px 0px, rgb(255, 255, 255) -0.287171px 2.98622px 0px, rgb(255, 255, 255) -1.24844px 2.72789px 0px, rgb(255, 255, 255) -2.07227px 2.16926px 0px, rgb(255, 255, 255) -2.66798px 1.37182px 0px, rgb(255, 255, 255) -2.96998px 0.42336px 0px, rgb(255, 255, 255) -2.94502px -0.571704px 0px, rgb(255, 255, 255) -2.59586px -1.50383px 0px, rgb(255, 255, 255) -1.96093px -2.27041px 0px, rgb(255, 255, 255) -1.11013px -2.78704px 0px, rgb(255, 255, 255) -0.137119px -2.99686px 0px, rgb(255, 255, 255) 0.850987px -2.87677px 0px, rgb(255, 255, 255) 1.74541px -2.43999px 0px, rgb(255, 255, 255) 2.44769px -1.73459px 0px, rgb(255, 255, 255) 2.88051px -0.838247px 0px; */
        text-shadow: rgb(60, 60, 60) 4px 0px 0px, rgb(60,60,60) 2.83487px 0.981584px 0px, rgb(60,60,60) 2.35766px 1.85511px 0px, rgb(60,60,60) 1.62091px 2.52441px 0px, rgb(60,60,60) 0.705713px 2.91581px 0px, rgb(60,60,60) -0.287171px 2.98622px 0px, rgb(60,60,60) -1.24844px 2.72789px 0px, rgb(60,60,60) -2.07227px 2.16926px 0px, rgb(60,60,60) -2.66798px 1.37182px 0px, rgb(60,60,60) -2.96998px 0.42336px 0px, rgb(60,60,60) -2.94502px -0.571704px 0px, rgb(60,60,60) -2.59586px -1.50383px 0px, rgb(60,60,60) -1.96093px -2.27041px 0px, rgb(60,60,60) -1.11013px -2.78704px 0px, rgb(60,60,60) -0.137119px -2.99686px 0px, rgb(60,60,60) 0.850987px -2.87677px 0px, rgb(60,60,60) 1.74541px -2.43999px 0px, rgb(60,60,60) 2.44769px -1.73459px 0px, rgb(60,60,60) 2.88051px -0.838247px 0px;
    }
    .fightingIcon, .marksmanshipIcon, .stealthIcon, .armorLevelIcon {
        filter: invert(0);
        z-index: 2;
    }
    .filledPoint {
        background-color: rgba(40, 40, 40, 0.2);
    }
    .rangeSlot {
        border: 0.7mm solid #333333;
        background-color: #fff;
    }
    .rangeFill {
        background-color: #666;
    }
    .rangedWeapon .reloadIcon {
        border: 1mm solid #333;
        background-color: #fff;
    }
    .utensRange  {
        background-color: #fff;
        background-blend-mode: hard-light;
    }
}




.page > nav {
    z-index: 4;
    display: grid;
    gap:2vw;
    grid-template-columns: 1fr 1fr 8fr;
    align-items: center;
    text-align: center;
}
.page > nav  .portraitDimensions {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
.page > nav > div {
    display: grid;
    gap:2vw;
    grid-template-columns: repeat(auto-fit, minmax(10px,1fr));
    align-items: center;
    justify-items: center;
}
@media (max-width:800px) {
    .page > nav .portraitDimensions {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
    .page > nav > div {
        display: grid;
        gap:2vw;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        text-align: center;
    }
}

/* .page:has(nav) {
    margin-top: calc(var(--appHeader-height) + var(--tools-height) + 5vh);
} */
.page h2 > span {
    color:#fff;
    font-size: 120%;
}

@media (max-width: 800px) {
    .page {
        width:100vw;
        height:100vh;
        overflow: scroll;
    }
    .page > nav {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .page > nav  .portraitDimensions {
        font-size: 70%;;
    }
    /* .page:has(nav) {
        margin-top: calc(var(--appHeader-height) + var(--tools-height) + 12vh);
    } */

}


.xRange {
    direction: rtl;
}