.Character {
    /* margin-top: calc(var(--appHeader-height) + var(--tools-height)); */
    margin-top: var(--appMarginTop);
    padding: var(--text-padding);
    text-align: justify;
}

.Character label {
    display: grid;
    /* width: 100vw; */
  }