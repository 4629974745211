.HomeContent {
    /* margin-top: calc(var(--appHeader-height) + 12vh); */
    margin-top: var(--appMarginTop);
    text-align: justify;
}
/* .HomeContent:has(nav) {
    margin-top: calc(var(--appHeader-height) + var(--tools-height));
}
.HomeContent:has(header) {
    margin-top: calc(var(--appHeader-height) + var(--big-tools-height));
} */
@media (max-width:800px) {
     .HomeContent {
        /* margin-top: calc(var(--appHeader-height) + 17vh);
         */
        margin-top: var(--appMarginTop);
        text-align: justify;
    } 
    /* .HomeContent:has(nav) {
        margin-top: calc(var(--appHeader-height) + var(--tools-height) + 8vh);
    }
    .HomeContent:has(header) {
        margin-top: calc(var(--appHeader-height) + var(--big-tools-height) + 8vh);
    }  */
}