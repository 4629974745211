.Listing {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal {
    overflow-y:scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    max-height: 50vh;
}
/* .modal::-webkit-scrollbar { 
    display: none; 
} */
.Listing > * {
    width:90vw;
}
.Listing ul,
.Listing li {
    text-align: left;
}
.Listing ul {
    display: grid;
    gap:1vw;
    align-items: center;
    
}
.Listing ul li {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    gap:2vw;
    border-bottom: #555 1px solid;
    border-image: var(--border-img-li);
    padding-bottom: 2vh;
    /* padding:0 2vw; */
}
/* .Listing ul li div {
    text-align: center;
}
.Listing ul li div:nth-child(1) {
    text-align: right;
} */
.excerpt {
    max-height:calc(10rem * var(--line-height));
    overflow: hidden;
}
.Listing img {
    max-width: 15vh;
}
.Filters {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap:1.5vw;
}

.Filters.Lexicons {
    grid-template-columns: 4fr 6fr 6fr 1fr 3fr;
    /* grid-template-columns: repeat(auto-fit, minmax(1px, 6fr)); */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* justify-content: first baseline; */
    padding-right: 2vw;
}
.Filters.Lexicons > *{
    display: grid;
}
.LexType {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap:1vw;
}

.Filters:not(.Lexicons) .search,
.Filters .checkboxes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1vw;
}


.Filters label {
    display: flex;
    gap:0.2vw;
    align-items: center;
}
.Filters .search {
    text-align: left;
}
.Filters .search label {
    position: relative;
}
.Filters .search label input[type='text'] {
    width: 100%;
}
.Filters .search button {
    background: none;
    color: currentColor;
    border: none;
    outline: none;
    position: absolute;
    box-shadow: none;
    top:0;
    right: 0;
    text-transform: uppercase;
    display: flex;
    padding: 0;
    height:100%;
    width:10%;
    align-items: center;
    justify-content: center;
}
.labelTitle {
    text-transform: uppercase;
}
.priceFilter {
    max-width: 4rem;
}
@media (max-width: 800px) {
    .Filters {
        grid-template-rows:1fr 1fr;
        grid-template-columns: 1fr;
        gap:1.5vh;
        padding-right: 1vw;
    }
    .labelTitle {
        display: none;
    }
    .Filters.Lexicons {
        grid-template-columns: 40vw 40vw;
        grid-template-rows:1fr 1fr;
    }
    .Filters.Lexicons .checkboxes {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width:800px) {
        .Filters .checkboxes {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr;
        }
    }
    /* .Filters.Lexicons > * {
        display: grid;
        
    } */
}

.noCharacters {
    display: grid;
    grid-template-rows: auto;
    gap:4vh;
    justify-content: center;
    text-align: center;
    padding-top:10vh;
}