
.itemEdit {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5,auto);
    gap:2vh;
}
.itemEdit > div:not(:empty):not(:last-child) {
    border-bottom: 1px solid #212121;
    padding: 0.5vh;
    display: grid;
    justify-content: stretch;
    align-items: center;
    gap:1vw;
}
.itemEdit .section {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));; */
}

.itemEdit .options > div{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    gap:1.5vw;
}
.itemEdit .options .subtypeSelect {
    display: block;
}
.itemEdit .options .stat label {
    display: grid;
    grid-template-rows: 2fr 3fr;
}

.itemEdit .description {
    display: grid;
    grid-template-columns: 1fr;
}
.itemEdit .description textarea {
    width: 100%;
}

.itemEdit .universes {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    /* justify-content: center; */
    /* align-items: baseline;  */
}
.itemEdit .universes label {
    display: flex;
    gap:0.5vw;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}


.itemEdit .options .rangeCheckboxes {
    display: grid;
    grid-template-columns: repeat(3,auto);
    margin: 2vh 0;
}
.itemEdit .options .range span {
    display: grid;
    grid-template-rows: 1fr;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 0;
    gap:0.3vh;
    min-height: 2.5vh;
}





