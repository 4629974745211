.modalBcg {
    background: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
}
.modalBox::-webkit-scrollbar { 
    display: none; 
}
.modalBox {
    /* background-color: var(--default-bg-color); */
    /* background: var(--decorative-bg3); */
    border: var(--decorative-border1-base);
    border-image: var(--decorative-border1);
    min-height:30vh;
    min-width: 40vw;
    max-width: 100%;
    max-height: 100vh;
    position: relative;
    margin:0 auto;
    padding: 1.5vw;
    text-align: center;
    animation: fadeIn 0.22s;
    overflow-y: hidden;
    box-shadow: inset 0em 0em 7em 7em var(--default-bg-color), inset 0em 9em 8em 18em #222;
}
.modalBox.close {
    animation: fadeOut 0.22s;
}
@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@media (max-width: 800px) {
    .modalBox {
        min-height:70vh;
        min-width: 100vw;
    }
}
.Xcloser {
    position: absolute;
    top:0px;
    right:0px;
    z-index: 8;
}
.modalBox .buttons button {
    margin-top:4vh;
    /* max-width: 40%; */
}
.buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(10px, 1fr));
    padding: 0 2vw;
    gap:2vw;
    justify-items: center;
}
