.loader {
    display: grid;
    text-align: center;
    align-items: center;
    grid-template-rows: 1fr 1fr 1fr;
}
.loaderImg {
    filter: invert(1) opacity(0.75);
    margin: 0 auto;
    mix-blend-mode: screen;
    background-image: url('../assets/preloader.gif');
    width: 200px;
    height: 138px;
}

@supports not (mix-blend-mode: screen) {
    .loaderImg {
        background-image: url('../assets/preloader-nobcg.gif');
    }
}

.loader button {
    animation: fadeIn 3s;
}
@keyframes fadeIn {
    0% { opacity:0; }
    65% { opacity:0; }
    100% { opacity:1; }
}