.auth {
    text-align: center;
    animation: fadeIn 1.5s;
    /* opacity: 0%; */
}

.errorMessage {
    color: #f00000;
    animation:pulse 1s infinite;
}

@keyframes pulse {
    0%{ opacity: 0.6; }
    50%{ opacity: 1; }
    100%{ opacity: 0.6; }
}
@keyframes fadeIn {
    0%{ opacity: 0%; }
    30%{ opacity: 0% }
    100%{ opacity: 100%; }
}