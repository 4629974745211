.DiceRoll {
    display:grid;
    gap:3vh;
}
.DiceRoll h4 {
    margin:0;
}

.DiceRoll h1.good {
    color:#4f7930;
}
.DiceRoll h1.bad {
    color:#a34747;
}
.DiceRoll h1.badLuck {
    color:#ff5353;
}
.DiceRoll h1.goodLuck {
    color:#e9e263;
}

.controls {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
}

.equals {
    opacity: 0.4;
    font-size: 80%;
}

.result {
    padding-bottom: 2vh;
}
.controls .modInput {
    font-size: 120%;
    width:40px;
    outline:0;
    border:0;
    /* margin-top:10px; */
    text-align: center;
    text-align-last: center;
    overflow: visible;
    appearance: none;
    -webkit-appearance: none;
}
.controls .modInputWrapper {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
}
.castButton {
    font-size: 120%;
    min-width:50%;
}
.die1, .die2, .die3, 
.die4, .die5, .die6,
.secondDie1, .secondDie2,
.secondDie3, .secondDie4,
.secondDie5, .secondDie6 {
    display: block;
    margin: 0 auto;
    margin-bottom: 2vh;
    width: 68px;
    height: 68px;
    background-image: url('../assets/dice-sprite.png');
    background-repeat: no-repeat;
} 
.die1, .secondDie1 {
    background-position: 0 0;
}
.die2, .secondDie2 {
    background-position: -68px 0;
}
.die3, .secondDie3 {
    background-position: -135px 0;
}
.die4, .secondDie4 {
    background-position: -203px 0;
}
.die5, .secondDie5 {
    background-position: -271px 0;
}
.die6, .secondDie6 {
    background-position: -339px 0;
}

.secondDie1, .secondDie2,
.secondDie3, .secondDie4,
.secondDie5, .secondDie6 {
    scale: 0.6;
}

.modifiedDiceResult {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.resultMod, .resltNumber {
    display: block;
    font-size: 200%;
}
.modifiedDiceResult {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 2vh;
}
.modifiedDiceResult div {
    margin: 5px;
    display: block;
    max-width:68px;
}
.modifiedDiceResult div:nth-child(1) {
    left:5vw;
}
.modifiedDiceResult div:nth-child(2) {
    right:5vw;
}

.fadeIn1 {
    animation: fadeIn 0.4s;
}
.fadeIn2 {
    animation: fadeIn 0.8s;
}
.fadeIn3 {
    animation: fadeIn 1.2s;
}
.dice1FadeIn {
    animation: diceFadeIn 0.4s;
}
.dice2FadeIn {
    animation: dice2FadeIn 0.8s;
}

@keyframes fadeIn {
    0% { 
        opacity:0; 
    }
    100% { 
        opacity:1; 
    }
}
@keyframes diceFadeIn {
    0% { 
        opacity:0; 
        transform: scale(2);
    }
    100% { 
        opacity:1; 
        transform: scale(1);
    }
}

@keyframes dice2FadeIn {
    0% { 
        opacity:0; 
        transform: scale(2);
    }
    100% { 
        opacity:1; 
        transform: scale(1);
    }
}

button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}