
.Filters {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap:1.5vw;
}

.Filters .checkboxes {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: center;
    gap:1vw;
}

@media (max-width:800px) {
    .Filters .checkboxes {
        grid-template-columns:1fr 1fr;
        grid-template-rows: auto;
    }
}

.Filters label {
    display: flex;
    gap:0.2vw;
    align-items: center;
}

.toPrint {
    /* margin-top: calc(var(--appHeader-height) + var(--tools-height));    */
    margin-top: var(--appMarginTop);
}
.frontPage {
    text-align: center;
    margin-bottom:15vh;
}
.logoToPrint {
    width:70%;
}
.rpg {
    margin-top:0;
}

@media print {
    @page { 
        size: A4 portrait;
        /* margin: 10mm 10mm 10mm 10mm;
        scale: 95; */
    }
    .frontPage {
        page-break-after:always 
    }
    a:link, a:visited, a:active, a:hover, * {
        color:#222;
    }
 }
 @media screen {
    .logoToPrint {
        filter: invert();
    }    
 }

