/* .Article {
    
} */
.articleContent {
    padding:4vh 0;
    padding: var(--text-padding);
    text-align: justify;
}

.articleContent h1,
.articleContent h2 {
    column-span: all;
}


.articleContent::first-letter {
    text-transform: uppercase;
    font-size: 300%;
    line-height: 0.33;
    color: var(--link-text-color);
    margin-right:0.3vw;
}

.articleContent mark {
    display: var(--draftDisplay);
    background-color: rgb(66, 66, 0);
    color: currentColor;
}

.controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2vw;
}
.edition {
    padding: var(--text-padding);
    display: grid;
    grid-template-rows: auto;
    gap:2vh;
}
.edition form {
    display: grid;
    grid-template-rows: auto;
    gap:2vh;
}
.edition form > *{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
}
.edition label {
    display: block;
}
.edition .growWrap {
    display: grid;
}

.edition .growWrap > textarea {
  width:100%;
  min-height:50vh;
  height:fit-content;
  max-height: none;
  overflow: hidden;
  resize: vertical;
}

.deletedInfo {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height:60vh;
    animation: infoFadeIn 1.3s;
}

@keyframes infoFadeIn {
    0%{ opacity: 0 }
    100%{ opacity: 1 }
}

.articleContent img {
    float:left;
    margin: 1vh 1vw;
    border: var(--decorative-border2-base);
    border-image: var(--decorative-border2);
    max-width:100%;
}