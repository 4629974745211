.points {
    position: relative;
    /* top:30vh; */
    /* right:2vw; */
    display: flex;
    width: 100%;
    height: 6.5vh;
    color:currentColor;
    z-index:6;
    gap:2vw;
    padding:1vh;
    justify-content: center;
    justify-items: center;
    align-items: center;
    /* background-color: rgb(83, 0, 0); */
    border-top: 1px #555 solid;
    border-bottom: 1px #555 solid;
    border-image:var(--border-points-img);
    /* border-radius: 50%; */
}
.points > * {
    position: relative;
}
.progressBar {
    position:absolute;
    /* background: rgb(50, 0, 0); */
    background: var(--bg-img-points);
    top:0;
    left:0;
    height:100%;
    animation: barSlowBlink 4s infinite;
}
.pointsValue {
    font-size: 160%;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#eee;
}
@keyframes updatePoints {
    0%{ transform: scale(200%); }
    100%{ transform: scale(100%); }
}
.spent {
    font-size:150%;
    line-height: 0;
    display: block;
    margin-bottom: 1.5vh;
}
.spent.update {
    animation: updatePoints 0.2s;
}
.pointsValueExceed {
    animation: barBlink 1s infinite;
    background: rgb(123, 0, 0);
}
.overSpent {
    color:rgb(255, 61, 61);
}

@keyframes barBlink {
    0%{opacity:100%;}
    100%{opacity:0%;}
}
@keyframes barSlowBlink {
    0%{opacity:100%;}
    50%{opacity:65%;}
    100%{opacity:100%;}
}
.budgetButtons {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:1vw;
}
.budgetButtons button {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}