.Progress,
.ProgressEdit {
    display:grid;
    /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    /* grid-template-columns: repeat(6,1fr); */
    grid-template-columns: 1fr 2fr 1fr 1fr;
    padding:1vh 2vw;
    gap:2vw;
    min-height: 10vh;
}




.Progress > *,
.ProgressEdit > *{
    display: flex;
    gap:1vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
}
.ProgressEdit input {
    max-width:100%;
}
.ProgressEdit label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.ProgressEdit .priceInput {
    border:none;
    max-width: 4vw;
}
.Progress .price,
.ProgressEdit .priceInput {
    font-size: 120%;
    color: #fff;
}
.ProgressEdit textarea {
    /* width:100%; */
    height:20vh;
    width:50vw;
    display:flex;
    resize:none;
}
.slider {
    display: flex;
    gap:1vw;
    align-items: center;
    width:20vw;
}
.progressBar {
    position:relative;
    width:20vw;
    height:2vh;
    border: 1px solid var(--i-text-number-password-border-color);
}
.progressBar div {
    position: absolute;
    height: 100%;
    background-color: var(--link-text-color);
    border:none;
    outline:none;
}
@media (min-width:801px) {
    .progressBar div {
        box-shadow: inset 0.5vh 0.5vh 0 #000, inset -0.5vh -0.5vh 0 #000;
    }
}

@media (max-width:800px) {
    .Progress,
    .ProgressEdit {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .slider,
    .progressBar {
        width:60%;
    }
}

.Progress:hover {
    cursor: pointer;
    background-color: #161616;
}