@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,800;1,400;1,800&family=Vollkorn+SC&display=swap');

:root {
  --line-height:0.8;
  --charsLeft-opacity:0.4;
  --input-min-h:27px;
  --coverLogo-height: 60vh;
  --normalLogo-height: 9vh;
  --mobileLogo-height: 6vh;
  --appHeader-height: 15vh;
  --appMarginTop:0;
  --draftDisplay:none;
  --text-padding:6vh 4vw;
  --text-color:#bbb;
  --lbl-color:#777;
  --default-bg-color:#070707ff;
  --button-bg-color:#55473e;
  --button-bg-secondColor:#1f170b;
  --button-bg-h-color:#8d500e;
  --button-bg-a-color:#d5a619;
  --button-bg-d-color:#4a4a4a;
  --button-bg:radial-gradient(circle at 50% 100%, var(--button-bg-color) 0, var(--button-bg-secondColor) 70%);
  --button-h-bg:radial-gradient(circle at 50% 0%, var(--button-bg-h-color) 0, var(--button-bg-secondColor) 80%);
  --button-a-bg:radial-gradient(circle at 50% 50%, var(--button-bg-a-color) 0, var(--button-bg-color) 80%);
  --button-border-color:#f9f3c0;
  --button-border-h-color:#fff;
  --button-border-d-color:#707070;
  --button-border-img:radial-gradient(circle at 50% 30%, var(--button-border-color), transparent) 10%;
  --button-border-h-img:radial-gradient(circle at 50% 30%, var(--button-border-h-color), transparent) 15%;
  --button-border-d-img:radial-gradient(circle at 50% 30%, var(--button-border-d-color), transparent) 20%;
  --button-text-color:#e6dbb6;
  --button-text-h-color:#fff;
  --button-text-d-color:#a3a3a3;
  --link-text-color:#e7d494;
  --link-text-h-color:#fff;
  --h-bg-color:#0b3b44;
  --bullet-color:#f9f3c0;
  --i-text-number-password-border-color:#6b5853;
  --i-text-number-password-border-h-color:#ab684c;
  --border-img-h2:linear-gradient(to right, currentColor, transparent) 1;
  --checkbox-border-img: radial-gradient(transparent, var(--button-border-color)) 1;
  --checkbox-border-f-img: radial-gradient(transparent, var(--button-border-h-color)) 1;
  --checkbox-border-d-img: radial-gradient(transparent, var(--button-border-d-color)) 1;
  --options-bg:#333333cc;
  --decorative-border1:url('./assets/border.png') 120 stretch;
  --decorative-border2:url('./assets/border2.png') 30 stretch;
  --decorative-border3:url('./assets/border-simple.png') 30 stretch;
  --decorative-border1-base:50px solid var(--default-bg-color);
  --decorative-border2-base:30px solid var(--default-bg-color);
  --decorative-border3-base:30px solid var(--default-bg-color);
  --decorative-bg1:radial-gradient(#222d2d, var(--default-bg-color)) no-repeat;
  --decorative-bg2:radial-gradient(circle at 50% -50%, #29020b 0, var(--default-bg-color) 130%);
  --decorative-bg3:radial-gradient(circle at 50% 150%, #211b21 0, var(--default-bg-color) 130%);
  --decorative-bg4:radial-gradient(circle at 0% 150%, #212121 0, var(--default-bg-color) 50%);
  --tools-height:6.5vh;
  --big-tools-height:12.5vh;
  --tools-mobile-height:13vh;
  --border-img-li:linear-gradient(to right, #444444ff, #44444433, #444444ff) 1;
  --border-points-img:radial-gradient(circle at 50% 30%, var(--button-border-color), transparent, transparent) 15%;
  --bg-img-points:radial-gradient(circle at 110% 100%, #860000 0, #410000 30%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Alegreya', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #1a1a1a;*/
  background-color: var(--default-bg-color);
  color:var(--text-color);
}

h1, h2, h3, h4 {
  font-weight: 300;
  /* text-align: center; */
  display: block;
  padding:6px 30px;
  text-transform: uppercase;
  /* text-align: left; */
}
h2 {
  border-bottom: #555 1px solid;
  border-image: var(--border-img-h2);
  margin:0;
  text-align: left;
  /* background: radial-gradient(circle at 20% 220%, var(--h-bg-color) 0, #00000080 40%) */
}
h1 {
  text-align: center;
  margin:0;
}

li {
  list-style-image: radial-gradient(circle at -40% 50%, var(--bullet-color) 0, transparent 90%);
}

input[type='text'], 
input[type='number'], 
input[type='password'], 
input[type='email'], 
textarea {
  border: 1px solid var(--i-text-number-password-border-color);
  border-radius: 0%;
  color: currentColor;
  background-color: transparent;
  font-family: 'Alegreya', serif;
  min-height: var(--input-min-h);
  font-size: 100%;
}

input[type='text']:focus, 
input[type='number']:focus, 
input[type='password']:focus, 
input[type='email']:focus, 
textarea:focus, 
input[type='checkbox']:focus {
  border: 1px solid var(--i-text-number-password-border-h-color);
  border-radius: 0%;
  outline:none;
}
input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:focus,
input[type='password']:-webkit-autofill,
input[type='password']:-webkit-autofill:focus,
:-moz-autofill, 
:autofill, 
:-moz-autofill-preview {
  transition: background-color 600000s 0s, color 600000s 0s;
  filter: none;
  filter: unset;
  background-color: var(--default-bg-color);
  box-shadow: inset 3em 3em var(--default-bg-color);
}

summary {
  cursor: pointer;
  color:var(--link-text-color);
}
summary:hover { 
  color:var(--link-text-h-color);
}

button {
  font-family: 'Alegreya', serif;
  background: var(--button-bg);
  color:var(--button-text-color);  
  /* border-bottom: #555 1px solid; */
  border: #555 1px solid;
  border-image: var(--button-border-img);
  padding: 5px 15px;
  box-shadow: 0px 0px 2px 1px #00000080;
  text-transform: uppercase;
  font-size: 80%;
  /* border-image: url('./assets/button-border-hover.png') 33.333333% stretch; */
}

@media (max-width:800px) {
  button {
    font-size: 70%;
  }
}

button:hover, button:active {
  background: var(--button-h-bg);
  border-image: var(--button-border-h-img);
  color: var(--button-text-h-color);
  cursor: pointer;
  outline: none;
  transition: all 0.4s;
}
button:active {
  background: var(--button-a-bg);
  transition: all 0.8s;
}
button:disabled {
  background: var(--button-d-bg);
  color: var(--button-text-d-color);
  border-image: var(--button-border-d-img);
  opacity: 0.5;
}

button.linkLikeButton, button.linkLikeButton:hover, button.linkLikeButton:active {
  background:none;
  border: none;
  outline: none;
  text-transform: none;
  box-shadow: none;
}

a:link, a:visited, .linkLikeButton, button.linkLikeButton:active, button.linkLikeButton:focus {
  color: var(--link-text-color);
  text-decoration: none;
}
a:active, a:hover, button.linkLikeButton:hover {
  color: var(--link-text-h-color);
}

input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: currentColor 2px solid;
  border-image: var(--checkbox-border-img);
  /* border-radius: 0.15em; */
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--link-text-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:focus {
  border-image: var(--checkbox-border-f-img);
  border: 2px solid currentColor;
  box-shadow: none;
  appearance: none;
  outline: 0;
}

input[type="checkbox"]:disabled {
  border-image: var(--checkbox-border-d-img);
  opacity:0.7;
}
input[type="checkbox"]:disabled::before {
  box-shadow: inset 1em 1em currentColor;
  opacity:0.7;
}

.rSelect-control {
  border: 1px solid var(--i-text-number-password-border-color);
  color: currentColor;
  font-family: 'Alegreya', serif;
  padding-left: 0.5vw;
  /* font-size: 80%; */
  min-height: var(--input-min-h) !important;
  /* max-width: 30vw; */
}
.rSelect-control-focus {
  border: 1px solid var(--i-text-number-password-border-h-color);
  padding-left: 0.5vw;
  min-height: var(--input-min-h) !important;
  /* font-size: 80%; */
}
.rSelect-menuList {
  background-color: var(--options-bg);
  /* font-size: 80%; */
}
.rSelect-option-focus {
  background-color: var(--button-bg-h-color);
  /* font-size: 80%; */
  min-height: var(--input-min-h);
}

.tools {
  display: flex;
  position: fixed;
  justify-content: stretch;
  padding:1vh 1vw;
  top:var(--appHeader-height);
  left:0;
  background: #111;
  width: 100%;
  /* height: var(--tools-height); */
  border-bottom: #444 1px solid;
  z-index: 2;
  transition: all 0.5s;
}
.lbl {
  color:var(--lbl-color);
  display: inline;
}
.modalTools {
  /* background: var(--decorative-bg3); */
  padding:2vh 0;
  border-bottom:1px solid #666;
  border-image:var(--button-border-img);
  /* position: fixed;
  width:100%;
  top:auto;
  left:0%; */
}
.modalTools h4 {
  padding:0;
  margin-top:0;
  margin-bottom: 2vh;
}
.modalTools:empty,
.tools:empty {
  display: none;
}

@media (max-width:800px)
{
  .tools button {
    padding: 1vw;
  }
}   





input[type=range] {
  appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
  margin-top: -0.8vh; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: var(--i-text-number-password-border-color);
  /* border-radius: 1.3px; */
  border: 0.2px solid var(--i-text-number-password-border-color);
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--i-text-number-password-border-color);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: var(--i-text-number-password-border-color);
  /* border-radius: 1.3px; */
  border: 0.2px solid var(--i-text-number-password-border-color);
}

input[type=range]::-ms-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 2vh 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--i-text-number-password-border-color);
  border: 0.2px solid var(--i-text-number-password-border-color);
  /* border-radius: 2.6px; */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--i-text-number-password-border-color);
}
input[type=range]::-ms-fill-upper {
  background: var(--i-text-number-password-border-color);
  border: 0.2px solid var(--i-text-number-password-border-color);
  /* border-radius: 2.6px; */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--i-text-number-password-border-color);
}
input[type=range] {
  appearance: none;
  /* margin: 18px 0; */
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: var(--i-text-number-password-border-color);
  /* border-radius: 1.3px; */
  border: 0.2px solid var(--i-text-number-password-border-color);
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.8vh;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--i-text-number-password-border-color);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: var(--i-text-number-password-border-color);
  /* border-radius: 1.3px; */
  border: 0.2px solid var(--i-text-number-password-border-color);
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 1vh;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 2vh 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--i-text-number-password-border-color);
  border: 0.2px solid var(--i-text-number-password-border-color);
  /* border-radius: 2.6px; */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: var(--i-text-number-password-border-color);
  border: 0.2px solid var(--i-text-number-password-border-color);
  /* border-radius: 2.6px; */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid var(--i-text-number-password-border-color);
  height: 2vh;
  width: 2vh;
  /* border-radius: 3px; */
  background: var(--link-text-color);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--i-text-number-password-border-color);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--i-text-number-password-border-color);
}


.hint {
  position:fixed;
  bottom:5vh;
  left:0;
  width: 100%;
  /* display:flex; */
  height: fit-content;
  padding:1vh 0.5vw;
  text-align: center;
  animation: hintFadeIn 0.1s;
  background: #101010;
  border-top: #555 1px solid;
  border-image: var(--button-border-img);
  font-size: 80%;
  transform-origin: bottom;
  z-index: 4;
  /* border-image:var(--bg-img-points); */
}
.hint::after {
  display: block;
  content: 'Podpowiedź:';
  text-transform: uppercase;
  position: absolute;
  top:0.3vh;
  width: 100%;
  text-align: center;
}

@media (max-width:800px) {
  .hint {
    bottom:9vh;
  }
}

*:not(:focus-within) .hint {
  display: none;
}

@keyframes hintFadeIn {
  0%{ 
    /* opacity:50%;  */
    transform: scaleY(0%);
    /* background: #6e5b44; */
  }
  30%{
    /* background: #898d3c; */
    transform: scaleY(170%);
  }
  100%{ 
    /* opacity:100%;  */
    transform: scaleY(100%);
    background: #101010;
  }
}



/* Firefox */
html {
  scrollbar-color: var(--link-text-color) #333;
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  background-color: #333;
}

::-webkit-scrollbar-thumb {
  background: var(--link-text-color);
  /* border-radius: 5px; */
}



/*Classes for articles*/
@media (min-width:801px) {
  .columns {
    column-count: 2;
    columns:2;
    column-gap: 1.5vw;
    column-fill: balance;
    -moz-column-count: 2;
    -moz-columns:2;
    -moz-column-gap: 1.5vw;
    -moz-column-fill: auto;
}
  .columnsBalanced {
      column-count: 2;
      columns:2;
      column-gap: 1.5vw;
      column-fill: balance;
      -moz-column-count: 2;
      -moz-columns:2;
      -moz-column-gap: 1.5vw;
      -moz-column-fill: auto;
  }
}