
.Utensilium,
.UtensiliumEdit {
    border-bottom: #555 1px solid;
    border-image: var(--border-img-li);
    padding-bottom: 2vh;
}
.Utensilium > h4,
.UtensiliumEdit > h4 {
    margin:1vh 0;
    padding-bottom:1vh;
}
.Utensilium > div,
.UtensiliumEdit > div {
    display:grid;
    /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    grid-template-columns: 1fr 2fr 2fr;
    padding:1vh 2vw;
    gap:2vw;
}
@media (max-width:800px) {
    .Utensilium > div,
    .UtensiliumEdit > div {
        grid-template-columns: 1fr 2fr 1fr;
    }
}

.Utensilium > * > *,
.UtensiliumEdit > * > *{
    display: flex;
    gap:1vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
}
.UtensiliumEdit input {
    max-width:100%;
}
.UtensiliumEdit label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.UtensiliumEdit .priceInput {
    border:none;
    max-width: 4vw;
}
.Utensilium .price,
.UtensiliumEdit .priceInput {
    font-size: 120%;
    color: #fff;
}
.UtensiliumEdit textarea {
    /* width:100%; */
    height:20vh;
    width:50vw;
    display:flex;
    resize:none;
}
.UtensiliumEdit input::-webkit-outer-spin-button,
.UtensiliumEdit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.UtensiliumEdit input[type=number] {
  appearance: textfield;
}

button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


.range {
    justify-content: center;
    background-image: url('../../assets/range_bck.png');
    background-repeat: no-repeat;
    background-size: 25vh;
    background-position:29% 0;
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: color-dodge;
}
.range > span {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:0.2vw;
}
.range span {
    display: grid;
    grid-template-rows: 1fr;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 0;
    gap:0.3vh;
    /* min-height: 2.5vh; */
    position: relative;
}
.rangeSlot {
    border: 3px solid #999;
    background-color: #333;
    border-radius: 50%;
    width:3vh;
    height: 3vh;
    position: absolute;
    top:-1vh;
    left:0;
}
.rangeFill {
    background-color: #999;
    border-radius: 50%;
    width:1.5vh;
    height: 1.5vh;
    /* position: absolute;
    top:0.4vh;
    left:0.4vh; */
}
.range > div:last-child {
    display: flex;
    align-items: center;
    padding-left: 6.8vw;
    padding-top: 4vh;
}

button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Utensilium:hover {
    cursor: pointer;
    background-color: #161616;
}