.AnchorsMenu {
    position: fixed;
    /* top:calc(var(--appHeader-height) + var(--tools-height) - 1vh); */
    bottom:-1vh;
    left:0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(10px,1fr));
    align-items: center;
    justify-content: center;
    background: var(--decorative-bg1);
    border-top: 1px solid #666;
    padding: 1.5vh;
    gap:2vw;
    font-size: 2.3vh;
    z-index:3;
    /* overflow:scroll; */
}
.AnchorsMenu button {
    text-align: center;
}
@media (max-width:800px) {
    .AnchorsMenu {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto;
    }
}
