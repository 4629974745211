.Mount > div,
.MountEdit > div {
    display:grid;
    /* grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    grid-template-columns: 1fr 3fr 1fr;
    padding:1vh 2vw;
    gap:2vw;
}
.Mount > div:has(section),
.MountEdit > div:has(section) {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
.Mount > * > *,
.MountEdit > * > *{
    display: flex;
    gap:1vw;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
}
.MountEdit input {
    max-width:100%;
}
.MountEdit label span {
    margin-left:0.5vw;
    opacity:var(--charsLeft-opacity);
}
.MountEdit .priceInput {
    border:none;
    max-width: 4vw;
}
.Mount .price,
.MountEdit .priceInput {
    font-size: 120%;
    color: #fff;
}
.MountEdit textarea {
    /* width:100%; */
    height:20vh;
    width:50vw;
    display:flex;
    resize:none;
}
.MountEdit input::-webkit-outer-spin-button,
.MountEdit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.MountEdit input[type=number] {
  appearance: textfield;
}

button.pointsButton {
    border-radius: 50%;
    border-image: none;
    border:1px solid var(--button-border-color);
    width:4vh;
    height:4vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Mount:hover {
    cursor: pointer;
    background-color: #161616;
}